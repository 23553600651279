import { Button, Card, CardActions, Grid, Typography } from "@mui/joy";
import AccountCalendarModal from "pages/Dash/Components/AccountCalendarModal/AccountCalendarModal";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function DashSettings() {
  const navigate = useNavigate();
  const [accountCalendarModalOpen, setAccountCalendarModalOpen] =
    useState(false);

  const SettingsComponent = () => {
    return (
      <>
        <AccountCalendarModal
          open={accountCalendarModalOpen}
          closeFn={(v) => setAccountCalendarModalOpen(v)}
        />
        <Grid container mt={3}>
          <Grid md={3}></Grid>
          <Grid xs={12} md={6}>
            <Typography
              marginTop={2}
              marginBottom={2}
              level="h3"
              textAlign={"center"}
            >
              Settings
            </Typography>

            <Card sx={{ mb: 2 }}>
              <Typography gutterBottom level="h4">
                Payouts
              </Typography>
              <Typography>
                View your historical and upcoming payouts.
              </Typography>
              <CardActions>
                <Button onClick={() => navigate("/dash/settings/payouts")}>
                  View
                </Button>
              </CardActions>
            </Card>

            <Card sx={{ mb: 2 }}>
              <Typography gutterBottom level="h4">
                Support Contact
              </Typography>

              <Typography>
                Set up your support contact information here. These details will
                appear anywhere where we send the customer details, such as
                emails.
              </Typography>
              <CardActions>
                <Button
                  onClick={() => navigate("/dash/settings/support-contact")}
                >
                  Change
                </Button>
              </CardActions>
            </Card>

            <Card sx={{ mb: 2 }}>
              <Typography gutterBottom level="h4">
                Calendars
              </Typography>

              <Typography>
                You can subscribe to your account calendar using the
                instructions below.
              </Typography>
              <CardActions>
                <Button onClick={() => setAccountCalendarModalOpen(true)}>
                  View Calendar Options
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid md={3}></Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {location.pathname === "/dash/settings" ? (
        <SettingsComponent />
      ) : (
        <Outlet />
      )}
    </>
  );
}
