/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@mui/joy";
import isEventComponentEnabled from "helpers/isEventComponentEnabled";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import ItineraryBuilder from "pages/Dash/Components/ItineraryBuilder/ItineraryBuilder";
import ListBuilder from "pages/Dash/Components/ListBuilder/ListBuilder";
import SectionHeader from "pages/Dash/Components/SectionHeader/SectionHeader";
import SkillLevelSelector from "pages/Dash/Components/SkillLevelSelector/SkillLevelSelector";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetEventByIdQuery,
  useUpdateEventMutation,
} from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardListingsItinerary() {
  const navigate = useNavigate();
  const { wizardId } = useParams();
  const dispatch = useDispatch();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const formMethods = useForm();
  const { t } = useTranslation();
  const [updateEventData, updateEventResponse] = useUpdateEventMutation();
  const [getEventData, eventResponse] = useLazyGetEventByIdQuery();
  const [saving, setSaving] = useState(false);
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });

  const onSubmit = async (data) => {
    console.log(data);
    console.log(wizardData);

    /**
     * TODO: Figure out how to do the following validation with react-hook-form
     */
    if (data.itineraryOverview.content.length === 0) {
      alert("Please fill in the itinerary overview section");
      return;
    }

    setSaving(true);

    await updateEventData({
      id: wizardData.results[0].eventId,
      updateData: {
        itineraryOverview: data.itineraryOverview,
        itineraryDayByDay: data.itineraryDayByDay,
        specificallyIncluded: data.specificallyIncluded,
        specificallyExcluded: data.specificallyExcluded,
        thingsToDoPaid: data.thingsToDoPaid,
        preTravelAdvisory: data.preTravelAdvisory,
        spokenLanguages: data.spokenLanguages,
        skillLevels: data.skillLevels,
        minimumAge: data.minimumAge,
        maximumAge: data.maximumAge,
        accompaniedByAdultAge: data.accompaniedByAdultAge,
        nonParticipatingChildrenAllowed: data.nonParticipatingChildrenAllowed,
      },
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);
        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Saved!",
          })
        );
        navigate(`/dash/wizard/${wizardId}/listing/food-and-drink`);
      })
      .catch((error) => {
        console.error("Error updating event", error);
        setSaving(false);
        alert("Error updating event, please try again.");
      });
  };

  useEffect(() => {
    if (eventResponse.isSuccess && eventResponse.data) {
      console.log("Event data", eventResponse.data);
      formMethods.setValue(
        "itineraryOverview",
        eventResponse.data.results[0].itineraryOverview
      );
      formMethods.setValue(
        "itineraryDayByDay",
        eventResponse.data.results[0].itineraryDayByDay
      );
      formMethods.setValue(
        "specificallyIncluded",
        eventResponse.data.results[0].specificallyIncluded
      );
      formMethods.setValue(
        "specificallyExcluded",
        eventResponse.data.results[0].specificallyExcluded
      );
      formMethods.setValue(
        "thingsToDoPaid",
        eventResponse.data.results[0].thingsToDoPaid
      );
      formMethods.setValue(
        "preTravelAdvisory",
        eventResponse.data.results[0].preTravelAdvisory
      );
      formMethods.setValue(
        "spokenLanguages",
        eventResponse.data.results[0].spokenLanguages
      );
      formMethods.setValue(
        "skillLevels",
        eventResponse.data.results[0].skillLevels
      );
      formMethods.setValue(
        "minimumAge",
        eventResponse.data.results[0].minimumAge
      );
      formMethods.setValue(
        "maximumAge",
        eventResponse.data.results[0].maximumAge
      );
      formMethods.setValue(
        "accompaniedByAdultAge",
        eventResponse.data.results[0].accompaniedByAdultAge
      );
      formMethods.setValue(
        "nonParticipatingChildrenAllowed",
        eventResponse.data.results[0].nonParticipatingChildrenAllowed
      );
    }
  }, [eventResponse]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getEventData(wizardData.results[0].eventId);
    }
  }, [wizardData]);

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      eventResponse.isSuccess &&
      !eventResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            {/**
             * Detailed Itinerary and Schedule section
             */}
            <Box m={2} />

            <SectionHeader title="Itinerary & Schedule Details" />

            <Box m={2} />

            {/**
             * Events: Itinerary overview
             */}

            <Box marginBottom={3}>
              <Typography gutterBottom level="title-lg">
                {t("dashboard.events.upsert.itinerary.title")}
              </Typography>

              <Typography
                gutterBottom
                level="body-md"
                // sx={{ whiteSpace: "pre-line" }}
              >
                {t("dashboard.events.upsert.itinerary.description")}
              </Typography>

              <Typography marginY={2} gutterBottom level="title-md">
                {t("dashboard.events.upsert.itinerary.overallInputTitle")}
              </Typography>

              <DashEditor
                name="itineraryOverview"
                required
                contentState={eventResponse.data.results[0].itineraryOverview}
              />
            </Box>

            {/**
             * Events: Itinerary Day by Day
             */}
            <Box marginBottom={3}>
              <Typography marginY={2} gutterBottom level="title-md">
                {t("dashboard.events.upsert.itinerary.dayByDayInputTitle")}
              </Typography>

              <ItineraryBuilder
                value={eventResponse.data.results[0].itineraryDayByDay ?? []}
                name="itineraryDayByDay"
              />
            </Box>

            {/**
             * Events: What's Included
             */}

            {isEventComponentEnabled(configData, "whatsIncluded") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.whatsIncluded.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.whatsIncluded.description")}
                </Typography>

                <ListBuilder
                  name="specificallyIncluded"
                  value={eventResponse.data.results[0].specificallyIncluded}
                />
              </Box>
            )}

            {/**
             * Events: What's Not Included
             */}
            {isEventComponentEnabled(configData, "whatsNotIncluded") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.whatsNotIncluded.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.whatsNotIncluded.description")}
                </Typography>

                <ListBuilder
                  name="specificallyExcluded"
                  value={eventResponse.data.results[0].specificallyExcluded}
                />
              </Box>
            )}

            {/**
             * Events: Optional Extras
             */}

            {isEventComponentEnabled(configData, "optionalExtras") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.optionalExtras.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.optionalExtras.description")}
                </Typography>

                <DashEditor
                  name="thingsToDoPaid"
                  contentState={eventResponse.data.results[0].thingsToDoPaid}
                />
              </Box>
            )}

            {/**
             * Events: Know before you go
             */}

            {isEventComponentEnabled(configData, "knowBeforeYouGo") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.knowBeforeYouGo.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.knowBeforeYouGo.description")}
                </Typography>

                <DashEditor
                  name="preTravelAdvisory"
                  contentState={eventResponse.data.results[0].preTravelAdvisory}
                />
              </Box>
            )}

            {/**
             * Events: Instruction Languages
             */}

            {isEventComponentEnabled(configData, "instructionLanguages") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.instructionLanguages.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t(
                    "dashboard.events.upsert.instructionLanguages.description"
                  )}
                </Typography>

                <Autocomplete
                  multiple
                  name="spokenLanguages"
                  options={["English", "French", "German"]}
                  label="Language"
                  onChange={(e, value) =>
                    formMethods.setValue("spokenLanguages", value)
                  }
                  defaultValue={eventResponse.data.results[0].spokenLanguages}
                />
              </Box>
            )}

            {/**
             * Events: Skill Level
             */}

            {isEventComponentEnabled(configData, "skillLevel") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.skillLevel.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.skillLevel.description")}
                </Typography>

                <SkillLevelSelector
                  name="skillLevels"
                  value={eventResponse.data.results[0].skillLevels}
                />
              </Box>
            )}

            {/**
             * Events: Minimum and Maximum Ages
             */}

            {isEventComponentEnabled(configData, "minimumMaximumAges") && (
              <Box marginBottom={3}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.events.upsert.minimumMaximumAges.title")}
                </Typography>

                <Typography gutterBottom level="body-md">
                  {t("dashboard.events.upsert.minimumMaximumAges.description")}
                </Typography>

                <Grid container spacing={2}>
                  <Grid xs={6}>
                    <FormControl>
                      <FormHelperText>
                        {t(
                          "dashboard.events.upsert.minimumMaximumAges.minimumInputLabel"
                        )}
                      </FormHelperText>
                      <Input
                        // value={eventResponse.data.results[0].minimumAge}
                        type="number"
                        slotProps={{ input: { min: 18 } }}
                        name="minimumAge"
                        label="Minimum"
                        {...formMethods.register("minimumAge", {
                          valueAsNumber: true,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl>
                      <FormHelperText>
                        {t(
                          "dashboard.events.upsert.minimumMaximumAges.maximumInputLabel"
                        )}
                      </FormHelperText>
                      <Input
                        // value={eventResponse.data.results[0].maximumAge}
                        type="number"
                        slotProps={{ input: { min: 18 } }}
                        name="maximumAge"
                        label="Maximum"
                        {...formMethods.register("maximumAge", {
                          valueAsNumber: true,
                        })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            )}

            {/**
             * Events: Minimum Age Participation
             */}

            {formMethods.getValues("minimumAge") < 18 && (
              <>
                <Box marginBottom={3}>
                  <Typography gutterBottom level="title-lg">
                    Can children participate?
                  </Typography>

                  <Typography gutterBottom level="body-md">
                    If the minimum age above is below 18, please tell us up to
                    what age they must be accompanied by an adult
                  </Typography>

                  <Input
                    size="lg"
                    sx={{ width: 100 }}
                    type="number"
                    slotProps={{ input: { min: 1 } }}
                    name="accompaniedByAdultAge"
                    label="Minimum Age"
                    // value={eventResponse.data.results[0].accompaniedByAdultAge}
                    {...formMethods.register("accompaniedByAdultAge")}
                  />
                </Box>

                <Box marginBottom={3}>
                  <Typography gutterBottom level="title-lg">
                    Are children allowed as non-participating guests?
                  </Typography>

                  <Typography gutterBottom level="body-md">
                    Please let us know if children are allowed to this event as
                    non-participating guests.
                  </Typography>

                  <Checkbox
                    value={true}
                    checked={
                      eventResponse.data.results[0]
                        .nonParticipatingChildrenAllowed
                    }
                    name="nonParticipatingChildrenAllowed"
                    label={"Children are allowed as non-participating guests"}
                    {...formMethods.register("nonParticipatingChildrenAllowed")}
                  />
                </Box>
              </>
            )}

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Save and Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
