/* eslint-disable no-unused-vars */
import { FiberManualRecord } from "@mui/icons-material";
import { CircularProgress, Input, Table, Typography } from "@mui/joy";
import Fuse from "fuse.js";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";
import { useNavigate } from "react-router-dom";
import { useGetOrdersQuery, useLazyGetOrdersQuery } from "services/osare";
let fuse = null;

export default function OrdersTable() {
  const {
    data: ordersData,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetOrdersQuery({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      console.log("setting new fuse");
      fuse = new Fuse(ordersData, { keys: ["_id", "shortId", "name"] });
    }
  }, [ordersData]);

  const navigate = useNavigate();

  const getOrderColor = (order) => {
    if (order.isAbandoned || order.isAwaitingApproval) {
      return "warning";
    } else if (order.isCancelledByCustomer) {
      return "danger";
    } else {
      return "success";
    }
  };

  const StatusToText = (order) => {
    if (order.isCancelledByCustomer) {
      return `${order.isDenied ? "Denied" : "Cancelled"} ${
        order.isCancelledByMerchant ? "by you" : "by the customer"
      }`;
    } else if (order.isAbandoned) {
      return "Abandoned by the customer";
    } else {
      if (order.isAwaitingApproval) {
        return "Waiting for your approval";
      } else {
        return "Received";
      }
    }
  };

  const OrderTableRow = (orderData) => {
    const [triggerOrderQuery, orderQueryResult] = useLazyGetOrdersQuery();
    const myRef = useRef(null);
    const { inViewport } = useInViewport(myRef);
    const [hydratedOrder, setHydratedOrder] = useState(null);

    useEffect(() => {
      if (inViewport) {
        console.log("in viewport");
        triggerOrderQuery({
          id: orderData._id,
          includePayments: true,
          resolveUser: true,
        });
      }
    }, [inViewport]);

    useEffect(() => {
      if (orderQueryResult.isSuccess) {
        console.log(orderQueryResult.data);
        setHydratedOrder(orderQueryResult.data[0]);
      }
    }, [orderQueryResult]);

    return (
      <tr ref={myRef}>
        <td>{hydratedOrder ? hydratedOrder.listingData.name : "Loading..."}</td>
        <td>
          <Typography
            color={getOrderColor(orderData)}
            level="body-sm"
            startDecorator={<FiberManualRecord />}
          >
            {StatusToText(orderData)}
          </Typography>
        </td>
        <td>
          <Typography>
            {hydratedOrder ? hydratedOrder.user.displayName : "Loading..."}
          </Typography>
        </td>
        <td>
          <Typography>
            {DateTime.fromMillis(orderData.createdAt).toRelative()}
          </Typography>
        </td>
        <td>
          <Typography fontFamily={"monospace"}>{orderData.shortId}</Typography>
          {/* <Card variant="outlined" color={getOrderColor(orderData)}>
            <Typography
              level="body-sm"
              startDecorator={<CalendarMonthTwoTone />}
            >
              Ordered {DateTime.fromMillis(orderData.createdAt).toRelative()} (
              {orderData.createdAt
                ? DateTime.fromMillis(orderData.createdAt).toLocaleString(
                    DateTime.DATETIME_MED_WITH_WEEKDAY
                  )
                : "(no date)"}
              )
            </Typography>
            <Typography
              color={getOrderColor(orderData)}
              level="body-sm"
              startDecorator={<FiberManualRecord />}
            >
              {StatusToText(orderData)}
            </Typography>
            <Typography mt={-1} ml={4} color="neutral" level="body-xs">
              Order ID: {orderData._id} / {orderData.shortId}
            </Typography>
            <Typography level="title-md">
              <Link
                onClick={() =>
                  navigate(`/dash/orders/single?id=${orderData._id}`, {
                    state: orderData,
                  })
                }
              >
                {isListingDataFetching
                  ? "Loading..."
                  : "View order for " +
                    listingData.results.find(
                      (l) => l._id === orderData.listingId
                    )?.name}
              </Link>
            </Typography>
          </Card> */}
        </td>
        <td>
          <Typography>
            {hydratedOrder
              ? `${hydratedOrder?.listingData.merchantAccount?.defaultCurrency.toUpperCase()} ${
                  hydratedOrder?.scheduledPayments[0]?.costBreakdown
                    ?.packageFee || "-"
                }`
              : "Loading..."}
          </Typography>
        </td>
      </tr>
    );
  };

  return (
    <>
      {isLoading || isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <Input
            placeholder="Search by Order ID / Booking ID"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (!fuse) {
                console.log("no fuse");
                return;
              }
              const searchResults = fuse.search(e.target.value);
              console.log(searchResults);
              setSearchResults(
                searchResults.length === 0
                  ? ordersData
                  : searchResults.map((r) => r.item)
              );
            }}
          />
          <Table
            sx={{ "& thead th:nth-child(1)": { width: "40%" } }}
            stickyHeader
          >
            <thead>
              <tr>
                <th>
                  {/* {searchResults.length === 0
                    ? ordersData.length
                    : searchResults.length}{" "}
                  Orders */}
                  Retreat
                </th>
                <th>Status</th>
                <th>Customer</th>
                <th>Date</th>
                <th>Order ID</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.length === 0
                ? ordersData.map((o) => <OrderTableRow key={o._id} {...o} />)
                : searchResults.map((o) => (
                    <OrderTableRow key={o._id} {...o} />
                  ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}
