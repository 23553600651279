/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/joy";
import SectionHeader from "pages/Dash/Components/SectionHeader/SectionHeader";
import StructureBuilder from "pages/Dash/Components/StructureBuilder/StructureBuilder";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCmsQuery,
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetAccommodationByIdQuery,
} from "services/osare";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardAccommodationRooms() {
  const formMethods = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizardId } = useParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });
  const [getAccommodationData, accommodationResponse] =
    useLazyGetAccommodationByIdQuery();
  const [saving, setSaving] = useState(false);
  const { data: cmsData } = useCmsQuery();
  const [hasRoomEdits, setHasRoomEdits] = useState(false);

  const onSubmit = async (data) => {
    console.log("Form data", data);

    if (hasRoomEdits) {
      if (
        confirm(
          "You have unsaved room changes. Are you sure you want to continue?"
        )
      ) {
        navigate(`/dash/wizard/${wizardId}/accommodation/location`);
      }
    } else {
      navigate(`/dash/wizard/${wizardId}/accommodation/location`);
    }
  };

  useEffect(() => {
    if (accommodationResponse.isSuccess && accommodationResponse.data) {
      console.log("Accommodation data", accommodationResponse.data);
      // formMethods.setValue("name", listingResponse.data.results[0].name);
    }
  }, [accommodationResponse]);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getAccommodationData(wizardData.results[0].accommodationId);
    }
  }, [wizardData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      accommodationResponse.isSuccess &&
      !accommodationResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <SectionHeader
              title={t("dashboard.accommodation.upsert.bookableSpaces.title")}
              subtitle={t(
                "dashboard.accommodation.upsert.bookableSpaces.description"
              )}
            />

            <Box marginY={2}>
              <StructureBuilder
                editMode={true}
                accommodationId={wizardData.results[0].accommodationId}
                accommodationData={accommodationResponse.data.results[0]}
                cmsData={cmsData}
                styles={accommodationResponse.data.results[0].styles}
                editedRoomsFn={(r) => {
                  console.log("edited rooms? ", r);
                  setHasRoomEdits(r);
                }}
              />
            </Box>

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
