import { useEffect } from "react";

import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import { Box, Grid, Typography } from "@mui/joy";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { firebaseApp } from "../helpers/firebase";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        navigate("/onboard");
      }
    });
  }, []);

  // Page load
  useEffect(() => {
    var ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseApp.auth());
    ui.start("#firebaseui-auth-container", {
      signInFlow: "popup",
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      tosUrl: "https://app.getterms.io/view/iwska/tos/en-au",
      // Privacy policy url/callback.
      privacyPolicyUrl: "https://app.getterms.io/view/iwska/privacy/en-au",
      callbacks: {
        signInSuccessWithAuthResult: function (authResult) {
          console.log("successful sign-in!", authResult);
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          navigate("/onboard");
          return false;
        },
      },
    });
  }, []);

  return (
    <>
      <Grid container xs={12}>
        <Grid xs={12}>
          <Box margin={5} />
          <Typography textAlign={"center"} level={"h1"}>
            {t("login.title")}
          </Typography>
          <Typography level="title-lg" textAlign={"center"}>
            {t("login.intro")}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Box margin={5} />
          <div id="firebaseui-auth-container" />
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
