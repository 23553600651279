/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import { Box, Button, Card, CircularProgress, Typography } from "@mui/joy";
import isAccommodationComponentEnabled from "helpers/isAccommodationComponentEnabled";
import DashEditor from "pages/Dash/Components/DashEditor/DashEditor";
import LocationSelector from "pages/Dash/Components/LocationSelector/LocationSelector";
import SectionHeader from "pages/Dash/Components/SectionHeader/SectionHeader";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetAccommodationByIdQuery,
  useUpdateAccommodationMutation,
} from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardAccommodationLocation() {
  const formMethods = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wizardId } = useParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });
  const [getAccommodationData, accommodationResponse] =
    useLazyGetAccommodationByIdQuery();
  const [updateAccommodationData, updateAccommodationResponse] =
    useUpdateAccommodationMutation();
  const [saving, setSaving] = useState(false);

  const onSubmit = async (data) => {
    console.log("Form data", data);

    /**
     * TODO: Figure out how to do the following validation with react-hook-form
     */
    if (!data.location.googlePlaceId) {
      alert("Please choose a location on the map first");
      return;
    }

    if (
      !data.locationDescription ||
      data.locationDescription.content.length === 0
    ) {
      alert("Please fill in the location description section");
      return;
    }

    // if (!data.weatherAdvisory || data.weatherAdvisory.content.length === 0) {
    //   alert("Please fill in the weather advisory section");
    //   return;
    // }

    // if (!data.seasonalAdvisory || data.seasonalAdvisory.content.length === 0) {
    //   alert("Please fill in the seasonal section");
    //   return;
    // }

    setSaving(true);

    await updateAccommodationData({
      id: wizardData.results[0].accommodationId,
      updateData: data,
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);

        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Saved!",
          })
        );

        navigate(`/dash/wizard/${wizardId}/pricing/booking-settings`);
      })
      .catch((error) => {
        console.error("Error updating accommodation", error);
        setSaving(false);
        alert("Error updating accommodation, please try again.");
      });
  };

  useEffect(() => {
    if (accommodationResponse.isSuccess && accommodationResponse.data) {
      console.log("Accommodation data", accommodationResponse.data);

      Object.keys(accommodationResponse.data.results[0]).forEach((key) => {
        formMethods.setValue(key, accommodationResponse.data.results[0][key]);
      });
    }
  }, [accommodationResponse]);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getAccommodationData(wizardData.results[0].accommodationId);
    }
  }, [wizardData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      accommodationResponse.isSuccess &&
      !accommodationResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <SectionHeader title="Location & Travel" />

            {isAccommodationComponentEnabled(configData, "mapSelector") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.map.title")}
                </Typography>
                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.map.description")}
                </Typography>

                <Card sx={{ p: 0 }}>
                  <LocationSelector
                    name="location"
                    value={accommodationResponse.data.results[0].location}
                  />
                </Card>
              </Box>
            )}

            {isAccommodationComponentEnabled(
              configData,
              "locationDescription"
            ) && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.location.title")}
                </Typography>
                <Typography level="body-lg">
                  {t("dashboard.accommodation.upsert.location.description")}
                </Typography>

                <DashEditor
                  name="locationDescription"
                  contentState={
                    accommodationResponse.data.results[0].locationDescription
                  }
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(
              configData,
              "directionsAdvisory"
            ) && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.directionsAdvisory.title")}
                </Typography>
                <Typography level="body-lg">
                  {t(
                    "dashboard.accommodation.upsert.directionsAdvisory.description"
                  )}
                </Typography>

                <DashEditor
                  name="directionsAdvisory"
                  contentState={
                    accommodationResponse.data.results[0].directionsAdvisory
                  }
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(configData, "weatherAdvisory") && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.weatherAdvisory.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t(
                    "dashboard.accommodation.upsert.weatherAdvisory.description"
                  )}
                </Typography>

                <DashEditor
                  name="weatherAdvisory"
                  contentState={
                    accommodationResponse.data.results[0].weatherAdvisory
                  }
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(
              configData,
              "seasonalAdvisory"
            ) && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.seasonal.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t("dashboard.accommodation.upsert.seasonal.description")}
                </Typography>

                <DashEditor
                  name="seasonalAdvisory"
                  contentState={
                    accommodationResponse.data.results[0].seasonalAdvisory
                  }
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(
              configData,
              "whatToBringAdvisory"
            ) && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t(
                    "dashboard.accommodation.upsert.whatToBringAdvisory.title"
                  )}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t(
                    "dashboard.accommodation.upsert.whatToBringAdvisory.description"
                  )}
                </Typography>

                <DashEditor
                  name="whatToBringAdvisory"
                  contentState={
                    accommodationResponse.data.results[0].whatToBringAdvisory
                  }
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(
              configData,
              "vaccinationsAdvisory"
            ) && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t(
                    "dashboard.accommodation.upsert.vaccinationsAdvisory.title"
                  )}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t(
                    "dashboard.accommodation.upsert.vaccinationsAdvisory.description"
                  )}
                </Typography>

                <DashEditor
                  name="vaccinationsAdvisory"
                  contentState={
                    accommodationResponse.data.results[0].vaccinationsAdvisory
                  }
                />
              </Box>
            )}

            {isAccommodationComponentEnabled(
              configData,
              "insuranceAdvisory"
            ) && (
              <Box marginY={2}>
                <Typography gutterBottom level="title-lg">
                  {t("dashboard.accommodation.upsert.insuranceAdvisory.title")}
                </Typography>

                <Typography gutterBottom level="body-lg">
                  {t(
                    "dashboard.accommodation.upsert.insuranceAdvisory.description"
                  )}
                </Typography>

                <DashEditor
                  name="insuranceAdvisory"
                  contentState={
                    accommodationResponse.data.results[0].insuranceAdvisory
                  }
                />
              </Box>
            )}

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Save and Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
