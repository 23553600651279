import { ArrowForward, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  Input,
  Typography,
} from "@mui/joy";
import CancellationRangeBuilder from "pages/Dash/Components/CancellationRangeBuilder/CancellationRangeBuilder";
import DepositSelector from "pages/Dash/Components/DepositSelector/DepositSelector";
import SectionHeader from "pages/Dash/Components/SectionHeader/SectionHeader";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetListingByIdQuery,
  useUpdateListingMutation,
} from "services/osare";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardPaymentOptions() {
  const { t } = useTranslation();
  const { wizardId } = useParams();
  const navigate = useNavigate();
  const formMethods = useForm();
  const [isChargingFullAmount, setIsChargingFullAmount] = useState(false);
  const [
    chargeRemainingAmountDaysBeforeTravel,
    setChargeRemainingAmountDaysBeforeTravel,
  ] = useState(0);
  const { isLoading: isConfigLoading } = useGetConfigQuery();
  const [isSaving, setSaving] = useState(false);
  const [isDepositRefundable, setIsDepositRefundable] = useState(false);
  const [getListingData, listingResponse] = useLazyGetListingByIdQuery();
  const [updateListingData] = useUpdateListingMutation();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getListingData({
        id: wizardData.results[0].listingId,
      });
    }
  }, [wizardData]);

  useEffect(() => {
    if (listingResponse.isSuccess && listingResponse.data) {
      console.log("Listing data", listingResponse.data);
      formMethods.setValue(
        "depositPercentage",
        listingResponse.data.results[0].depositPercentage || 20
      );

      setChargeRemainingAmountDaysBeforeTravel(
        listingResponse.data.results[0].chargeRemainingAmountDaysBeforeTravel ||
          0
      );
      formMethods.setValue(
        "chargeRemainingAmountDaysBeforeTravel",
        listingResponse.data.results[0].chargeRemainingAmountDaysBeforeTravel ||
          0
      );

      setIsChargingFullAmount(
        listingResponse.data.results[0].isChargingFullAmount || false
      );
      formMethods.setValue(
        "isChargingFullAmount",
        listingResponse.data.results[0].isChargingFullAmount || false
      );

      setIsDepositRefundable(
        listingResponse.data.results[0].isDepositRefundable || false
      );

      formMethods.setValue(
        "isDepositRefundable",
        listingResponse.data.results[0].isDepositRefundable || false
      );

      // setIsDepositRefundable(
      //   listingResponse.data.results[0].isDepositRefundable || false
      // );

      // formMethods.setValue(
      //   "isDepositRefundable",
      //   listingResponse.data.results[0].isDepositRefundable || false
      // );
    }
  }, [listingResponse]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const onSubmit = async (data) => {
    console.log(data);

    await updateListingData({
      id: wizardData.results[0].listingId,
      updateData: {
        ...data,
      },
    })
      .unwrap()
      .then(() => {
        console.log("saved!");
        setSaving(false);
        navigate(`/dash/wizard/${wizardId}/finished`);
      })
      .catch((error) => {
        console.error("Error updating event", error);
        setSaving(false);
        alert("Error updating listing, please try again.");
      });
  };

  formMethods.watch("depositPercentage");
  formMethods.watch("isChargingFullAmount");

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      listingResponse.isSuccess &&
      !listingResponse.isFetching ? (
        <>
          <FormProvider {...formMethods}>
            <SectionHeader title="Payment & Refunds" />

            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Box marginBottom={3}>
                <Typography gutterBottom level="body-lg"></Typography>
                <Box mt={3} mb={2}>
                  <Typography level="title-lg">Payment Options</Typography>
                  <Typography gutterBottom level="body-md" mb={2}>
                    Would you like to charge the full amount upfront, or take a
                    deposit now and collect the remaining balance later? Choose
                    the option that works best for your retreat.
                  </Typography>

                  <Checkbox
                    label="Collect Full Payment Upfront"
                    size="lg"
                    checked={isChargingFullAmount}
                    onChange={() => {
                      setIsChargingFullAmount(!isChargingFullAmount);
                      formMethods.setValue(
                        "isChargingFullAmount",
                        !isChargingFullAmount
                      );
                    }}
                  />
                </Box>

                {!isChargingFullAmount && (
                  <Box>
                    <Box ml={4.5} marginBottom={3}>
                      <Typography level="title-lg">
                        {t("dashboard.listings.upsert.deposit.title")}
                      </Typography>
                      <Typography gutterBottom level="body-md">
                        {t("dashboard.listings.upsert.deposit.description")}
                      </Typography>

                      <DepositSelector
                        listingData={
                          listingResponse.isSuccess &&
                          listingResponse.data.results[0]
                        }
                      />
                    </Box>

                    <Box ml={4.5} mb={3}>
                      <Typography level="title-lg">
                        Remaining Balance
                      </Typography>
                      <Typography gutterBottom level="body-md" mb={1}>
                        Set how many days before travel you would like us to
                        charge the remaining balance.
                      </Typography>
                      <FormControl>
                        <Input
                          required
                          size="lg"
                          sx={{ width: 400 }}
                          slotProps={{ input: { min: 1 } }}
                          type="number"
                          value={chargeRemainingAmountDaysBeforeTravel}
                          startDecorator={
                            <Typography>Charge the remainder</Typography>
                          }
                          placeholder="14"
                          endDecorator={
                            <Typography>days before travel</Typography>
                          }
                          onChange={(e) => {
                            setChargeRemainingAmountDaysBeforeTravel(
                              e.target.value
                            );
                            formMethods.setValue(
                              "chargeRemainingAmountDaysBeforeTravel",
                              Number(e.target.value)
                            );
                          }}
                        />
                      </FormControl>
                    </Box>

                    <Box ml={4.5} marginBottom={3}>
                      <Typography level="title-lg">
                        Refundable Deposits
                      </Typography>
                      <Typography gutterBottom level="body-md" mb={1}>
                        Decide if deposit refunds will be offered for
                        cancellations.
                      </Typography>

                      <Alert color="warning">
                        Note: The selection below is used for informational
                        purposes only. The platform will not process refunds for
                        you.
                      </Alert>

                      <Box mt={3}>
                        <Checkbox
                          size="lg"
                          checked={isDepositRefundable}
                          onChange={(e) => {
                            console.log(e.target.checked);
                            setIsDepositRefundable(!isDepositRefundable);
                            formMethods.setValue(
                              "isDepositRefundable",
                              !isDepositRefundable
                            );
                          }}
                          label="This listing offers refundable deposits"
                        />
                      </Box>

                      {isDepositRefundable && (
                        <Box mt={2}>
                          <Alert
                            sx={{ alignItems: "flex-start" }}
                            color="danger"
                            startDecorator={<Warning fontSize="small" />}
                          >
                            <div>
                              <div>Refundable Deposit Warning</div>
                              <Typography level="body-sm">
                                <b>Important:</b> Our commission is deducted
                                from the deposit. If a traveller cancels, you
                                must refund the full amount they paid, leaving
                                you short of our commission. To prevent this and
                                ensure you don&apos;t lose money, we recommend
                                making deposits non-refundable.
                              </Typography>
                            </div>
                          </Alert>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}

                <Box marginBottom={3}>
                  <Typography level="title-lg">
                    Refunds <Chip>Optional</Chip>
                  </Typography>
                  <Typography gutterBottom level="body-md" mb={1}>
                    You can choose to build a refund timeline of days and refund
                    percentages before travel.
                  </Typography>

                  <Typography color="warning">
                    Note: The refund timeline below is used for informational
                    purposes only. The platform will not process refunds for
                    you.
                  </Typography>

                  <Box mt={2} />

                  <Card>
                    <CancellationRangeBuilder
                      cancellationRanges={
                        listingResponse.data.results[0].cancellationRanges
                      }
                    />
                  </Card>
                </Box>

                <Box mt={1}>
                  <Typography level="title-lg">
                    Review Payment Options
                  </Typography>
                  <Typography gutterBottom level="body-md" mb={1}>
                    Please check and review the payment settings that you have
                    chosen below
                  </Typography>

                  {isChargingFullAmount && (
                    <Box>
                      <Typography fontFamily={"monospace"} fontWeight={"bold"}>
                        - Payment type: Full amount immediately
                      </Typography>
                      {formMethods.watch("cancellationRanges", []).length >
                      0 ? (
                        <Typography
                          fontFamily={"monospace"}
                          fontWeight={"bold"}
                        >
                          - Refunds are active
                        </Typography>
                      ) : (
                        <Typography
                          fontFamily={"monospace"}
                          fontWeight={"bold"}
                        >
                          - Refunds are not set - no refunds will be given in
                          the event of customer cancellation
                        </Typography>
                      )}
                    </Box>
                  )}

                  {!isChargingFullAmount && (
                    <Box>
                      <Typography fontWeight={"bold"} fontFamily={"monospace"}>
                        - Payment type: Split Payment
                      </Typography>

                      <Typography
                        startDecorator={<ArrowForward />}
                        fontFamily={"monospace"}
                        ml={2}
                      >
                        {formMethods.watch("depositPercentage", 20)}% deposit,
                        charged immediately
                      </Typography>

                      <Typography
                        startDecorator={<ArrowForward />}
                        fontFamily={"monospace"}
                        gutterBottom
                        ml={2}
                      >
                        Remaining balance charged{" "}
                        {formMethods.watch(
                          "chargeRemainingAmountDaysBeforeTravel"
                        )}{" "}
                        days before travel
                      </Typography>
                      {formMethods.watch("cancellationRanges", []).length >
                      0 ? (
                        <Typography
                          fontWeight={"bold"}
                          fontFamily={"monospace"}
                        >
                          - Cancellation ranges are active
                        </Typography>
                      ) : (
                        <Typography
                          fontWeight={"bold"}
                          fontFamily={"monospace"}
                        >
                          - Cancellation ranges are not set
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box mt={3}>
                <Button
                  disabled={
                    (chargeRemainingAmountDaysBeforeTravel === "0" ||
                      !chargeRemainingAmountDaysBeforeTravel) &&
                    !isChargingFullAmount &&
                    !isSaving
                  }
                  type="submit"
                >
                  Save &amp; Continue
                </Button>
              </Box>
            </form>
          </FormProvider>
        </>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
