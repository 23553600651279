import {
  Box,
  Button,
  Grid,
  Input,
  Link,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export default function CancellationRangeBuilder(props) {
  // eslint-disable-next-line no-unused-vars
  const { setValue } = useFormContext();
  const [cancellationRanges, setCancellationRanges] = useState(
    props.cancellationRanges ?? []
  );
  const [newCancellationDay, setNewCancellationDay] = useState(7);
  const [newCancellationPercentage, setNewCancellationPercentage] =
    useState(80);

  const addCancellationRange = () => {
    console.log("Add Cancellation Range:");
    console.log(newCancellationDay, newCancellationPercentage);

    // const lastEntry = cancellationRanges[cancellationRanges.length - 1];
    // Get the last entry in the array
    const lastEntry = cancellationRanges.slice(-1)[0];

    console.log("lastEntry:", lastEntry);

    if (lastEntry !== undefined) {
      if (Number(newCancellationPercentage) < 1) {
        {
          alert(
            "Sorry, the cancellation percentage you entered must be greater than 1%. Please check and try again."
          );
          return;
        }
      }

      if (Number(newCancellationDay) <= Number(lastEntry.day)) {
        alert(
          `Sorry, the day you entered (${newCancellationDay}) must be greater than the last day cancellation day you entered (${lastEntry.day}). Please check and try again.`
        );
        return;
      }

      if (Number(newCancellationPercentage) > Number(lastEntry.percentage)) {
        alert(
          `Sorry, the percentage you entered (${newCancellationPercentage}%) must be less than the last percentage you entered (${lastEntry.percentage}%). Please check and try again.`
        );
        return;
      }
    }

    const newCancellationData = [
      ...cancellationRanges,
      {
        day: Number(newCancellationDay),
        percentage: Number(newCancellationPercentage),
      },
    ];

    // Work out new percentage value
    let adjustedCancelaltionPercentage =
      newCancellationPercentage - 20 < 1 ? 1 : newCancellationPercentage - 20;

    setCancellationRanges(newCancellationData);
    setValue("cancellationRanges", newCancellationData);
    setNewCancellationDay(newCancellationDay + 7);
    setNewCancellationPercentage(adjustedCancelaltionPercentage);
  };

  // eslint-disable-next-line no-unused-vars
  const removeCancellationRange = (index) => {
    console.log("Remove Cancellation Range");
    const updatedRange = cancellationRanges.filter((item, i) => i !== index);
    setCancellationRanges(updatedRange);
    setValue("cancellationRanges", updatedRange);
  };

  useEffect(() => {
    console.log("cancellation ranges:", cancellationRanges);
    setCancellationRanges(cancellationRanges);
    setValue("cancellationRanges", cancellationRanges);
  }, []);

  return (
    <Box>
      <Stepper orientation="vertical">
        {cancellationRanges.length > 0 && (
          <Step indicator={<StepIndicator variant="solid" color="primary" />}>
            <Typography>
              <b>Booking date</b>
            </Typography>
            <Stack spacing={0}>
              <Box marginLeft={2} my={2}>
                <Typography fontFamily={"monospace"} fontSize={21}>
                  {cancellationRanges[0].percentage}%{" "}
                  <Typography fontFamily={"sans-serif"}>refund </Typography>
                  <Link
                    onClick={() => removeCancellationRange(0)}
                    color="danger"
                  >
                    (remove)
                  </Link>
                </Typography>
                <Typography level="body-sm">
                  Between the <b>booking date</b> and{" "}
                  <b>day {cancellationRanges[0].day}</b> - an{" "}
                  <b>{cancellationRanges[0].percentage}% refund</b> is being
                  offered
                </Typography>
              </Box>
            </Stack>
          </Step>
        )}

        {cancellationRanges.map((row, index) => {
          /**
           * get the next row in the array
           */
          const nextRow = cancellationRanges[index + 1];

          return (
            <Step
              key={index}
              indicator={<StepIndicator variant="solid" color="primary" />}
            >
              <Typography>
                <b>
                  {row.day} day{row.day > 1 && "s"}
                </b>{" "}
                after booking:
              </Typography>
              <Stack spacing={0}>
                <Box marginLeft={2} my={2}>
                  <Typography fontFamily={"monospace"} fontSize={21}>
                    {nextRow ? nextRow?.percentage + "%" : "No"}
                    <Typography marginLeft={1} fontFamily={"sans-serif"}>
                      refund
                    </Typography>
                    {nextRow && (
                      <Link
                        marginLeft={1}
                        onClick={() => removeCancellationRange(index + 1)}
                        color="danger"
                      >
                        (remove)
                      </Link>
                    )}
                  </Typography>
                  {nextRow && (
                    <Typography level="body-sm">
                      Between <b>day {row.day}</b> and{" "}
                      <b>day {nextRow?.day} </b>after booking - a{" "}
                      <b>{nextRow?.percentage}% refund</b> will be offered
                    </Typography>
                  )}
                  {!nextRow && (
                    <Typography level="body-sm">
                      <b>No refunds</b> are offered after {row.day} day
                      {row.day > 1 && "s"}{" "}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Step>
          );
        })}
        {cancellationRanges.length === 0 && (
          <Step indicator={<StepIndicator variant="solid" color="primary" />}>
            <Typography>
              <b>No refund timeline added yet.</b>
            </Typography>
            <Stack spacing={0}>
              <Box marginLeft={2} my={2}>
                <Typography>
                  This means that no refunds will be offered after booking. To
                  begin adding a refund timeline, use the box below. This step
                  is optional.
                </Typography>
              </Box>
            </Stack>
          </Step>
        )}

        <Step indicator={<StepIndicator variant="soft" color="primary" />}>
          <Box>
            <Grid container spacing={2}>
              <Grid xs={5}>
                <Input
                  type="number"
                  endDecorator={<Typography>days after booking</Typography>}
                  slotProps={{
                    input: {
                      min: 1,
                    },
                  }}
                  value={newCancellationDay}
                  onChange={(e) => {
                    console.log(Number(e.target.value));
                    setNewCancellationDay(Number(e.target.value));
                  }}
                />
              </Grid>
              <Grid xs={5}>
                <Input
                  type="number"
                  startDecorator={<Typography>Refund percentage:</Typography>}
                  endDecorator={<Typography>%</Typography>}
                  value={newCancellationPercentage}
                  onChange={(e) =>
                    setNewCancellationPercentage(Number(e.target.value))
                  }
                />
              </Grid>
              <Grid xs={2}>
                <Button fullWidth onClick={() => addCancellationRange()}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Step>
      </Stepper>
    </Box>
  );
}
