/* eslint-disable no-unused-vars */

import "./styles.css";

import { Button, ButtonGroup, Card } from "@mui/joy";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import Placeholder from "@tiptap/extension-placeholder";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className="control-group">
      <ButtonGroup size="sm">
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          Bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          Italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          Bullet list
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          Ordered list
        </Button>
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          Undo
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          Redo
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default function DashEditor(props) {
  const { setValue, formState, register, setError, setFocus } =
    useFormContext();

  const [editorContent, setEditorContent] = useState(
    !props.contentState ||
      !props.contentState.content ||
      props.contentState.content.length === 0
      ? {
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "",
                },
              ],
            },
          ],
          type: "doc",
        }
      : props.contentState
  );

  const extensions = [
    StarterKit,
    Placeholder.configure({
      placeholder: props.placeholder || "Start typing a description here...",
    }),
  ];

  useEffect(() => {
    console.log(formState.errors);
  }, [formState.errors]);

  if (props.required) {
    register(props.name, { required: true });
  }

  useEffect(() => {
    if (formState.errors[props.name]) {
      setFocus(props.name);
    }
  }, [formState.errors]);

  return (
    <Card>
      <EditorProvider
        slotBefore={<MenuBar />}
        extensions={extensions}
        content={editorContent}
        editorContainerProps={{
          style: {
            background: "white",
            paddingLeft: 10,
            border: "1px solid #d6d6d6",
            borderRadius: 8,
          },
        }}
        onContentError={(e) => {
          console.error(e);
        }}
        onUpdate={(editor) => {
          console.log(editor.editor.getJSON());
          setEditorContent(editor.editor.getJSON());
          setValue(props.name, editor.editor.getJSON());
          if (props.onChange) {
            props.onChange(editor.editor.getJSON());
          }
        }}
      ></EditorProvider>
    </Card>
  );
}
