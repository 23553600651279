import axios from "axios";
import imageCompression from "browser-image-compression";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import {
  RemoveCircle,
  UploadFile,
  UploadFileRounded,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/joy";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useCreateMediaMutation,
  useDeleteMediaMutation,
  useGetMediaQuery,
  useUpdateMediaMutation,
} from "services/osare";

// eslint-disable-next-line no-unused-vars
export default function GalleryManager(props) {
  // console.log("Incoming props for GalleryManager:", props);
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [mediaItems, setMediaItems] = useState([]);
  const [uploadedMediaItems, setUploadedMediaItems] = useState([]);
  const [createMedia] = useCreateMediaMutation();
  const [updateMedia] = useUpdateMediaMutation();
  const [deleteMedia] = useDeleteMediaMutation();
  const { data: accommodationMedia, refetch: refetchAccommodationMedia } =
    useGetMediaQuery(props);

  useEffect(() => {
    setValue("media", uploadedMediaItems);
    refetchAccommodationMedia();
  }, [uploadedMediaItems]);

  useEffect(() => {
    if (accommodationMedia) {
      setValue("media", accommodationMedia?.results);
      setUploadedMediaItems(accommodationMedia?.results);
    }
  }, [accommodationMedia]);

  const deleteMediaItem = async (id) => {
    // Start delete media process
    const updatedUploadedMediaItems = [...uploadedMediaItems];

    // Find the entry by name, remove it and rewrite mediaItems
    const idx = updatedUploadedMediaItems.findIndex((item) => item.id === id);
    const updatedMediaItem = {
      ...updatedUploadedMediaItems[idx],
      state: "deleting",
    };
    // Replace the object at position idx with updatedMediaItem
    updatedUploadedMediaItems[idx] = updatedMediaItem;
    setUploadedMediaItems([...updatedUploadedMediaItems]);

    // TODO: error control
    const deleteResult = await deleteMedia(id).unwrap();
    console.log("deleteResult", deleteResult);

    updatedUploadedMediaItems.splice(idx, 1);
    setUploadedMediaItems([...updatedUploadedMediaItems]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      console.log("file", file);

      const reader = new FileReader();

      // Define event callbacks
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () =>
        alert("Sorry, there was an error reading the file. Please try again.");
      reader.onload = () => {
        const mediaItem = {
          id: file.name,
          serverMediaId: false,
          file,
          fileReader: reader,
          image: false,
          state: "onload",
        };
        mediaItems.push(mediaItem);
        setMediaItems([...mediaItems]);
      };
      reader.onloadend = async () => {
        let compressedFile = false;
        // Load data into an image to get the dimensions / other information
        var image = new Image();
        image.src = reader.result;

        try {
          compressedFile = await imageCompression(file, {
            maxSizeMB: 1,
          });
          console.log(
            "compressedFile instanceof Blob",
            compressedFile instanceof Blob
          ); // true
          console.log(
            `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
          ); // smaller than maxSizeMB
        } catch (error) {
          console.log(error);
          compressedFile = file;
        }

        // Create the media container and get the pre-signed URL
        const createMediaResult = await createMedia({
          mimeType: file.type,
          collection: props.collection,
          collectionId: props.collectionId,
        }).unwrap();

        console.log("createMediaResult", createMediaResult);

        // Send the media tp S3
        await axios
          .put(createMediaResult.preSignedUrl, compressedFile)
          .then(() => {
            console.log("File uploaded successfully");
          });

        // Update the media container to mark it as uploaded
        const updateMediaResult = await updateMedia(createMediaResult.id, {
          isUploaded: true,
        }).unwrap();

        console.log("Media updated!", updateMediaResult);

        console.log("Setting uploadedMediaItems...");
        setUploadedMediaItems([...uploadedMediaItems, updateMediaResult]);

        // Find the entry by name, remove it and delete mediaItems
        const idx = mediaItems.findIndex((item) => item.id === file.name);
        mediaItems.splice(idx, 1);

        setMediaItems([...mediaItems]);
      };

      // Start the file read process
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: props.mediaType === "single" ? 1 : undefined,
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Card color="success" variant="soft">
            <Typography startDecorator={<UploadFileRounded />}>
              Drop the files here...
            </Typography>
          </Card>
        ) : (
          <>
            {props.mediaType === "single" &&
            uploadedMediaItems.length > 0 ? null : (
              <Card color="primary" variant="soft" sx={{ cursor: "pointer" }}>
                <Typography startDecorator={<UploadFile />}>
                  {t(
                    "dashboard.accommodation.upsert.photos.dragDropDescription"
                  )}
                </Typography>
              </Card>
            )}
          </>
        )}
      </div>

      <Grid container>
        {mediaItems.length > 0 ? (
          <Grid xs={12}>
            <Typography>Uploading...</Typography>
          </Grid>
        ) : null}
        {mediaItems.map((mediaItem) => (
          <Grid key={mediaItem.id} xs={3}>
            <Box
              style={{
                width: "100%",
                height: "150px",
                backgroundImage: "url(" + mediaItem.fileReader.result + ")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                opacity: mediaItem.state === "onloadend" ? 1 : 0.2,
              }}
            ></Box>
            {mediaItem.state === "onload" ? <LinearProgress /> : null}
            {mediaItem.state === "deleting" ? (
              <LinearProgress color="warning" />
            ) : null}
          </Grid>
        ))}

        {uploadedMediaItems.length > 0 ? (
          <Grid xs={12} marginTop={2} marginBottom={1}>
            <Typography level="title-md">Existing media:</Typography>
          </Grid>
        ) : null}

        {uploadedMediaItems.map((mediaItem) => (
          <Grid key={`uploaded-${mediaItem._id}`} xs={3}>
            <Box
              style={{
                width: "100%",
                height: "150px",
                backgroundImage: "url(" + mediaItem.signedUrl + ")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <IconButton
                sx={{ float: "right" }}
                color="danger"
                onClick={() => deleteMediaItem(mediaItem._id)}
              >
                <RemoveCircle />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
