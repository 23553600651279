/* eslint-disable no-unused-vars */
import { SaveAlt } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/joy";
import RequestToBookToggle from "pages/Dash/Components/RequestToBookToggle/RequestToBookToggle";
import SectionHeader from "pages/Dash/Components/SectionHeader/SectionHeader";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetConfigQuery,
  useGetWizardsQuery,
  useLazyGetListingByIdQuery,
  useUpdateListingMutation,
} from "services/osare";
import { triggerSuccessSnackbar } from "services/snackbars";
import WizardSectionLoading from "../WizardSectionLoading";

export default function WizardPricingBooking() {
  const formMethods = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wizardId } = useParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();
  const { data: wizardData, isLoading: isWizardDataLoading } =
    useGetWizardsQuery({
      query: {
        id: wizardId,
      },
    });
  const [getListingData, listingResponse] = useLazyGetListingByIdQuery();
  const [updateListingData, updateListingResponse] = useUpdateListingMutation();
  const [saving, setSaving] = useState(false);

  const onSubmit = async (data) => {
    console.log("Form data", data);

    /**
     * TODO: Figure out how to do the following validation with react-hook-form
     */
    // if (!data.location ?? !data.location.geometry) {
    //   alert("Please choose a location on the map first");
    //   return;
    // }

    // if (!data.weatherAdvisory || data.weatherAdvisory.content.length === 0) {
    //   alert("Please fill in the weather advisory section");
    //   return;
    // }

    // if (!data.seasonalAdvisory || data.seasonalAdvisory.content.length === 0) {
    //   alert("Please fill in the seasonal section");
    //   return;
    // }

    setSaving(true);

    await updateListingData({
      id: wizardData.results[0].listingId,
      updateData: data,
    })
      .unwrap()
      .then((data) => {
        console.log("saved!");
        setSaving(false);

        dispatch(
          triggerSuccessSnackbar({
            open: true,
            message: "Saved!",
          })
        );

        navigate(`/dash/wizard/${wizardId}/pricing/dates-prices`);
      })
      .catch((error) => {
        console.error("Error updating listing", error);
        setSaving(false);
        alert("Error updating listing, please try again.");
      });
  };

  useEffect(() => {
    if (listingResponse.isSuccess && listingResponse.data) {
      console.log("Listing data", listingResponse.data);

      Object.keys(listingResponse.data.results[0]).forEach((key) => {
        formMethods.setValue(key, listingResponse.data.results[0][key]);
      });
    }
  }, [listingResponse]);

  useEffect(() => {
    console.log("Wizard data", wizardData);
    if (wizardData?.results.length > 0) {
      getListingData({
        id: wizardData.results[0].listingId,
      });
    }
  }, [wizardData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {!isConfigLoading &&
      !isWizardDataLoading &&
      listingResponse.isSuccess &&
      !listingResponse.isFetching ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <SectionHeader title="Booking Options" />

            <Box marginBottom={3}>
              <Typography level="title-lg">
                {t("dashboard.listings.upsert.requestToBookMode.title")}
              </Typography>
              <Typography level="body-md">
                {t("dashboard.listings.upsert.requestToBookMode.description")}
              </Typography>

              <RequestToBookToggle
                name="ordersMustBeConfirmed"
                value={
                  listingResponse.isSuccess &&
                  listingResponse.data.results[0].ordersMustBeConfirmed
                }
              />
            </Box>

            <Button
              type="submit"
              startDecorator={
                saving ? <CircularProgress size="sm" /> : <SaveAlt />
              }
            >
              {saving ? "Saving..." : "Save and Continue"}
            </Button>
          </form>
        </FormProvider>
      ) : (
        <WizardSectionLoading />
      )}
    </>
  );
}
