import { DeleteForever, SaveAlt } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Input,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { Snackbar, Stack } from "@mui/material";
import isAccommodationComponentEnabled from "helpers/isAccommodationComponentEnabled";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  useCreateRoomMutation,
  useDeleteRoomMutation,
  useGetConfigQuery,
  useGetRoomsQuery,
  useUpdateRoomMutation,
} from "services/osare";
import {
  triggerSuccessSnackbar,
  triggerWarningSnackbar,
} from "services/snackbars";
import DashEditor from "../DashEditor/DashEditor";
import GalleryManager from "../GalleryManager/GalleryManager";

export default function StructureBuilder(props) {
  const dispatch = useDispatch();
  const [roomTypes, setRoomTypes] = useState(["Select new room"]);
  const { t } = useTranslation();
  const [createRoom, createRoomResult] = useCreateRoomMutation();
  const [updateRoom, updateRoomResult] = useUpdateRoomMutation();
  const [deleteRoom, deleteRoomResult] = useDeleteRoomMutation();
  // eslint-disable-next-line no-unused-vars
  const { data: fetchedRoomData = { results: [] }, refetch } = useGetRoomsQuery(
    {
      query: {
        accommodationId: props.accommodationId,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [roomsData, setRoomsData] = useState([]);
  const [searchParams] = useSearchParams();
  const [openUpdated, setOpenUpdated] = useState(false);
  // const [hasUnsavedRooms, setHasUnsavedRooms] = useState(false);
  const { data: configData } = useGetConfigQuery();

  // Create effect
  useEffect(() => {
    if (createRoomResult.status === "fulfilled") {
      console.log("A room was created!");
      refetch();
    }
  }, [createRoomResult.status, refetch]);

  // Delete effect
  useEffect(() => {
    if (deleteRoomResult.status === "fulfilled") {
      console.log("A room was deleted!");
      refetch();
    }
  }, [deleteRoomResult.status, refetch]);

  // Update effect
  useEffect(() => {
    console.log(updateRoomResult.status);
    console.log(updateRoomResult);
    if (updateRoomResult.status === "fulfilled") {
      console.log("A room was updated!");
      // setOpenUpdated(true);
      dispatch(
        triggerSuccessSnackbar({
          open: true,
          message: "Saved!",
        })
      );
    }

    if (updateRoomResult.status === "rejected") {
      dispatch(
        triggerWarningSnackbar({
          open: true,
          message: `Sorry there was an error saving the room: ${updateRoomResult.error.data.errors.join(
            ", "
          )}`,
        })
      );
    }
  }, [updateRoomResult.status, refetch]);

  // Update after add effect
  useEffect(() => {
    // TODO: check that .results exists
    console.log("fetchedRoomData:", fetchedRoomData);
    setRoomsData(fetchedRoomData.results);
  }, [fetchedRoomData]);

  useEffect(() => {
    const roomTypes = ["Select new room"];
    let joinedArr = [];

    if (props.cmsData && props.cmsData.results) {
      joinedArr = roomTypes.concat(
        props.cmsData.results.types.accommodationRoomTypes.map(
          (r) => r.data.name[0].text
        )
      );
    }

    setRoomTypes(joinedArr);
  }, [props.cmsData]);

  useEffect(() => {
    determineAnyUnsavedChanges();
  }, [roomsData]);

  /**
   * This function will cycle through all the
   * array items in the roomsData state and
   * determine if any of the rooms are unsaved.
   * It will then set a new state to indicate
   * that one of the rooms has been edited and
   * not yet saved.
   */
  const determineAnyUnsavedChanges = () => {
    let anyUnsavedChanges = false;

    roomsData.forEach((roomData) => {
      if (roomData.edited) {
        anyUnsavedChanges = true;
      }
    });

    // setSearchParams("hasUnsavedRooms", anyUnsavedChanges);
    console.log("Any unsaved changes?", anyUnsavedChanges);
    // setHasUnsavedRooms(anyUnsavedChanges);
    props.editedRoomsFn(anyUnsavedChanges);
  };

  const addRoomToFloor = (roomName) => {
    createRoom({
      accommodationId: props.accommodationId ?? searchParams.get("id"),
      name: roomName,
      sleepingArrangements: [],
    });
  };

  const toggleRoomFacilities = (roomData) => {
    // Set up
    const indexToUpdateAt = roomsData.findIndex((d) => d._id === roomData._id);
    let roomsDataClone = _.cloneDeep(roomsData);
    let roomDataClone = _.cloneDeep(roomData);

    // Edit the data we need to edit...
    const currentFlag = roomDataClone.amenitiesAvailable;
    roomDataClone.amenitiesAvailable = !currentFlag;

    // Mark as edited
    roomDataClone.edited = true;

    // Replace at position
    roomsDataClone[indexToUpdateAt] = roomDataClone;

    setRoomsData(roomsDataClone);
  };

  const changeAmenityToRoom = (roomData, category, amenity) => {
    // Set up
    const indexToUpdateAt = roomsData.findIndex((d) => d._id === roomData._id);
    let roomsDataClone = _.cloneDeep(roomsData);
    let roomDataClone = _.cloneDeep(roomData);

    // Straight overwrite here
    roomDataClone.amenities[category] = amenity;

    // Mark as edited
    roomDataClone.edited = true;

    // Replace at position
    roomsDataClone[indexToUpdateAt] = roomDataClone;
    setRoomsData(roomsDataClone);
  };

  const removeRoomFromSpace = (roomData) => {
    if (
      confirm(
        `Are you sure you want to delete this ${roomData.name} in ${props.accommodationData.name}?`
      ) == true
    ) {
      deleteRoom({
        id: roomData._id,
      });
    }
  };

  const addSleepingArrangementToRoom = (roomData, sleepingArrangementData) => {
    // Set up
    const indexToUpdateAt = roomsData.findIndex((d) => d._id === roomData._id);
    let roomsDataClone = _.cloneDeep(roomsData);
    let roomDataClone = _.cloneDeep(roomData);

    // Edit the data we need to edit...
    roomDataClone.sleepingArrangements.push({
      ...sleepingArrangementData,
    });

    // Mark as edited
    roomDataClone.edited = true;

    // Replace at position
    roomsDataClone[indexToUpdateAt] = roomDataClone;

    setRoomsData(roomsDataClone);
  };

  // eslint-disable-next-line no-unused-vars
  const removeSleepingArrangementInRoom = (
    roomData,
    sleepingArrangementPosition
  ) => {
    // Set up
    const indexToUpdateAt = roomsData.findIndex((d) => d._id === roomData._id);
    let roomsDataClone = _.cloneDeep(roomsData);
    let roomDataClone = _.cloneDeep(roomData);

    // Edit the data we need to edit...
    roomDataClone.sleepingArrangements.splice(sleepingArrangementPosition, 1);

    // Mark as edited
    roomDataClone.edited = true;

    // Replace at position
    roomsDataClone[indexToUpdateAt] = roomDataClone;

    setRoomsData(roomsDataClone);
  };

  const updateRoomDescription = (roomData, content) => {
    // Set up
    const indexToUpdateAt = roomsData.findIndex((d) => d._id === roomData._id);
    let roomsDataClone = _.cloneDeep(roomsData);
    let roomDataClone = _.cloneDeep(roomData);

    // Edit the data we need to edit...
    roomDataClone.description = content;

    // Mark as edited
    roomDataClone.edited = true;

    // Replace at position
    roomsDataClone[indexToUpdateAt] = roomDataClone;

    setRoomsData(roomsDataClone);
  };

  const updateRoomName = (roomData, content) => {
    // if (content.length === 0) content = "Room";
    // Set up
    const indexToUpdateAt = roomsData.findIndex((d) => d._id === roomData._id);
    let roomsDataClone = _.cloneDeep(roomsData);
    let roomDataClone = _.cloneDeep(roomData);

    // Edit the data we need to edit...
    roomDataClone.name = content;

    // Mark as edited
    roomDataClone.edited = true;

    // Replace at position
    roomsDataClone[indexToUpdateAt] = roomDataClone;

    setRoomsData(roomsDataClone);
  };

  const setRoomEditedFlag = (roomData, flag) => {
    // Set up
    const indexToUpdateAt = roomsData.findIndex((d) => d._id === roomData._id);
    let roomsDataClone = _.cloneDeep(roomsData);
    let roomDataClone = _.cloneDeep(roomData);

    // Edit the data we need to edit...
    roomDataClone.edited = flag;

    // Replace at position
    roomsDataClone[indexToUpdateAt] = roomDataClone;

    setRoomsData(roomsDataClone);
  };

  return (
    <>
      <Grid container spacing={2}>
        {roomsData.map((roomData) => {
          return (
            <Grid key={`room-${roomData._id}`} item xs={12}>
              <Card variant="outlined">
                <Typography level="title-lg">
                  {t("components.structureBuilder.roomNameTitle", {
                    roomName: roomData.name,
                  })}
                </Typography>
                <Typography gutterBottom>
                  {t("components.structureBuilder.roomNameDescription")}
                </Typography>
                <Input
                  value={roomData.name}
                  size="lg"
                  onChange={(e) => updateRoomName(roomData, e.target.value)}
                  placeholder={
                    roomData.name === ""
                      ? "Enter a room name here"
                      : roomData.name
                  }
                  color={roomData.name === "" ? "warning" : "default"}
                  endDecorator={
                    <Button
                      disabled={!roomData.edited}
                      color="success"
                      variant="soft"
                      startDecorator={<SaveAlt />}
                      onClick={() => {
                        updateRoom({
                          id: roomData._id,
                          updateData: roomData,
                        });

                        setRoomEditedFlag(roomData, false);
                      }}
                    >
                      Save
                    </Button>
                  }
                />
                <Typography level="title-lg" gutterBottom>
                  in {props.accommodationData.name}{" "}
                  <Chip
                    onClick={() => removeRoomFromSpace(roomData)}
                    color="danger"
                  >
                    Remove
                  </Chip>
                </Typography>
                <Typography>
                  {t("components.structureBuilder.roomDescription", {
                    roomName: roomData.name,
                  })}
                </Typography>
                <DashEditor
                  contentState={roomData.description}
                  onChange={(c) => updateRoomDescription(roomData, c)}
                />
                <Typography>
                  {t("components.structureBuilder.photosUpload", {
                    roomName: roomData.name,
                  })}
                </Typography>
                <GalleryManager
                  collection={"rooms"}
                  collectionId={roomData._id}
                />
                <Grid container>
                  <Grid xs={12}>
                    <Typography gutterBottom>
                      {t(
                        "components.structureBuilder.sleepingArrangementsSelect"
                      )}
                    </Typography>
                    {props.cmsData && props.cmsData.results && (
                      <Select
                        value={t("components.structureBuilder.bedTypeSelect")}
                        onChange={(e, v) =>
                          addSleepingArrangementToRoom(roomData, v)
                        }
                      >
                        {[
                          t("components.structureBuilder.bedTypeSelect"),
                          ...props.cmsData.results.types.accommodationSpaceBedTypes.map(
                            (s) => s.data
                          ),
                        ].map((i) => (
                          <Option key={`select-sa-${_.uniqueId()}`} value={i}>
                            {i.name
                              ? i.name[0].text
                              : t("components.structureBuilder.bedTypeSelect")}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Grid>
                  <Box marginTop={2}>
                    {roomData.sleepingArrangements.map(
                      (a, sleepingArrangementPosition) => {
                        return (
                          <Grid
                            key={`room-${roomData._id}-sa-${sleepingArrangementPosition}`}
                            item
                            xs={12}
                          >
                            <Stack direction={"row"}>
                              <IconButton
                                color="danger"
                                onClick={() =>
                                  removeSleepingArrangementInRoom(
                                    roomData,
                                    sleepingArrangementPosition
                                  )
                                }
                              >
                                <DeleteForever />
                              </IconButton>
                              <Typography padding={1}>
                                {a.name[0].text}
                              </Typography>
                            </Stack>
                          </Grid>
                        );
                      }
                    )}
                  </Box>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <Checkbox
                      checked={roomData.amenitiesAvailable}
                      onChange={() => toggleRoomFacilities(roomData)}
                      label={`I would also like to add ${roomData.name} facilities and amenities`}
                    />
                  </Grid>
                </Grid>

                {roomData.amenitiesAvailable ? (
                  <Card>
                    <Grid container spacing={2}>
                      {props.cmsData && props.cmsData.results && (
                        <>
                          <Grid item xs={12}>
                            <Typography level="title-lg" gutterBottom>
                              Facilities and amenities for this{" "}
                              <b>{roomData.name}</b>
                            </Typography>
                            <Typography gutterBottom>
                              The section below allows you to choose what
                              facilities and amenities are included in this{" "}
                              <b>{roomData.name}</b> for your guests to enjoy.
                              Please select all that apply.
                            </Typography>
                          </Grid>

                          {isAccommodationComponentEnabled(
                            configData,
                            "roomSustailability"
                          ) && (
                            <Grid item xs={12}>
                              <Typography level="title-md">
                                Sustainability
                              </Typography>

                              <Autocomplete
                                multiple
                                options={props.cmsData.results.types.accommodationSustainability.map(
                                  (v) => v.data.name[0].text
                                )}
                                placeholder="Please select all that apply"
                                defaultValue={roomData.amenities.sustainability}
                                onChange={(e, v) => {
                                  changeAmenityToRoom(
                                    roomData,
                                    "sustainability",
                                    v
                                  );
                                }}
                              />
                            </Grid>
                          )}

                          {isAccommodationComponentEnabled(
                            configData,
                            "roomEssentialsComfort"
                          ) && (
                            <Grid item xs={12}>
                              <Typography level="title-md">
                                Essentials &amp; Comfort
                              </Typography>

                              <Autocomplete
                                multiple
                                options={props.cmsData.results.types.accommodationSpaceEssentialsComfort.map(
                                  (v) => v.data.name[0].text
                                )}
                                placeholder="Please select all that apply"
                                defaultValue={roomData.amenities.comfort}
                                onChange={(e, v) => {
                                  changeAmenityToRoom(roomData, "comfort", v);
                                }}
                              />
                            </Grid>
                          )}

                          {isAccommodationComponentEnabled(
                            configData,
                            "roomFoodDrink"
                          ) && (
                            <Grid item xs={12}>
                              <Typography level="title-md">
                                Food & Drink Facilities
                              </Typography>

                              <Autocomplete
                                multiple
                                options={props.cmsData.results.types.accommodationSpaceFoodPreparation.map(
                                  (v) => v.data.name[0].text
                                )}
                                placeholder="Please select all that apply"
                                defaultValue={
                                  roomData.amenities.foodPreparation
                                }
                                onChange={(e, v) => {
                                  changeAmenityToRoom(
                                    roomData,
                                    "foodPreparation",
                                    v
                                  );
                                }}
                              />
                            </Grid>
                          )}

                          {isAccommodationComponentEnabled(
                            configData,
                            "roomPersonalCare"
                          ) && (
                            <Grid item xs={12}>
                              <Typography level="title-md">
                                Personal Care
                              </Typography>

                              <Autocomplete
                                multiple
                                options={props.cmsData.results.types.accommodationSpacePersonalCare.map(
                                  (v) => v.data.name[0].text
                                )}
                                placeholder="Please select all that apply"
                                defaultValue={roomData.amenities.personalCare}
                                onChange={(e, v) => {
                                  changeAmenityToRoom(
                                    roomData,
                                    "personalCare",
                                    v
                                  );
                                }}
                              />
                            </Grid>
                          )}

                          {isAccommodationComponentEnabled(
                            configData,
                            "roomBasics"
                          ) && (
                            <Grid item xs={12}>
                              <Typography level="title-md">
                                {t(
                                  "components.structureBuilder.roomBasicsTitle"
                                )}
                              </Typography>
                              <Typography gutterBottom level="title-sm">
                                {t(
                                  "components.structureBuilder.roomBasicsDescription"
                                )}
                              </Typography>

                              <Autocomplete
                                multiple
                                options={props.cmsData.results.types.accommodationSpaceBasics.map(
                                  (v) => v.data.name[0].text
                                )}
                                placeholder="Please select all that apply"
                                defaultValue={roomData.amenities.basics}
                                onChange={(e, v) => {
                                  changeAmenityToRoom(roomData, "basics", v);
                                }}
                              />
                            </Grid>
                          )}

                          {isAccommodationComponentEnabled(
                            configData,
                            "roomBathroom"
                          ) && (
                            <Grid item xs={12}>
                              <Typography level="title-md">
                                {t(
                                  "components.structureBuilder.roomBathroomTitle"
                                )}
                              </Typography>
                              <Typography gutterBottom level="title-sm">
                                {t(
                                  "components.structureBuilder.roomBathroomDescription"
                                )}
                              </Typography>

                              <Autocomplete
                                multiple
                                options={props.cmsData.results.types.accommodationSpaceBathroom.map(
                                  (v) => v.data.name[0].text
                                )}
                                placeholder="Please select all that apply"
                                defaultValue={roomData.amenities.bathroom}
                                onChange={(e, v) => {
                                  changeAmenityToRoom(roomData, "bathroom", v);
                                }}
                              />
                            </Grid>
                          )}

                          {isAccommodationComponentEnabled(
                            configData,
                            "roomExtras"
                          ) && (
                            <Grid item xs={12}>
                              <Typography level="title-md">
                                {t(
                                  "components.structureBuilder.roomExtrasTitle"
                                )}
                              </Typography>
                              <Typography gutterBottom level="title-sm">
                                {t(
                                  "components.structureBuilder.roomExtrasDescription"
                                )}
                              </Typography>

                              <Autocomplete
                                multiple
                                options={props.cmsData.results.types.accommodationSpaceExtras.map(
                                  (v) => v.data.name[0].text
                                )}
                                placeholder="Please select all that apply"
                                defaultValue={roomData.amenities.extras}
                                onChange={(e, v) => {
                                  changeAmenityToRoom(roomData, "extras", v);
                                }}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </Card>
                ) : null}

                {roomData.edited ? (
                  <Button
                    onClick={() => {
                      updateRoom({
                        id: roomData._id,
                        updateData: roomData,
                      });

                      setRoomEditedFlag(roomData, false);
                      dispatch(
                        triggerWarningSnackbar({
                          open: true,
                          message: "Saving...",
                        })
                      );
                    }}
                    variant="soft"
                    color="success"
                  >
                    Save {roomData.name}
                  </Button>
                ) : null}
              </Card>
            </Grid>
          );
        })}

        <Grid xs={12}>
          <Card color="success">
            <Typography
              startDecorator={<AddCircleOutlineIcon />}
              level="title-md"
              gutterBottom
            >
              {t("components.structureBuilder.addNewRoom", {
                pluralText: roomsData.length > 0 ? "another" : "a",
              })}
            </Typography>

            <Box>
              <Select
                value={"Select new room"}
                onChange={(e, v) => addRoomToFloor(v)}
              >
                {roomTypes.map((i) => (
                  <Option key={`roomtypes-${i}`} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openUpdated}
        autoHideDuration={5000}
        message={`Saved!`}
        key={"bottom" + "center"}
        onClose={() => setOpenUpdated(false)}
      />
    </>
  );
}
