/* eslint-disable no-undef */
import { CopyAll } from "@mui/icons-material";
import {
  Button,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { getAuth } from "firebase/auth";

export default function AccountCalendarModal(props) {
  const auth = getAuth();

  return (
    <Modal open={props.open} onClose={() => props.closeFn(false)}>
      <ModalDialog
        sx={(theme) => ({
          [theme.breakpoints.only("xs")]: {
            top: "unset",
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: "none",
            maxWidth: "unset",
          },
        })}
      >
        <DialogTitle>Account Calendar</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            The calendar link below is for subscribing to your account calendar
            in your Calendar app of choice. This calendar subscription will
            automatically be updated with any listings and their associated
            availability that you have created.
          </Typography>

          <Typography gutterBottom fontWeight={"bold"}>
            Copy the calendar subscription link below and import it to your
            Calendar app:
          </Typography>

          <Input
            readOnly
            value={`${process.env.REACT_APP_PUBLIC_PLATFORM_API}/calendars/account?firebaseUserId=${auth.currentUser.uid}&entity=metimeaway`}
            fullWidth
            endDecorator={
              <Button
                variant="soft"
                color="neutral"
                startDecorator={<CopyAll />}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_PUBLIC_PLATFORM_API}/calendars/account?firebaseUserId=${auth.currentUser.uid}&entity=metimeaway`
                  );
                  alert("Copied to clipboard!");
                }}
              >
                Copy
              </Button>
            }
          />
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}
